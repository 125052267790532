"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getJupiterSwapInstructions = exports.getJupiterPrice = exports.isSupportedChainId = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const constants_1 = require("./constants");
const jupiterService_1 = require("./jupiterService");
const isSupportedChainId = (chainId) => {
    return constants_1.jupiterSupportedChainIds.includes(chainId);
};
exports.isSupportedChainId = isSupportedChainId;
const JUPITER_TRANSACTION_MAX_ACCOUNTS = 54;
const getJupiterPrice = ({ apiUrl, sourceAsset, destinationAsset, commissionBps, amount, slippageBps, }) => jupiterService_1.jupiterService.get(`${apiUrl}/quote` +
    `?inputMint=${(0, caip_1.fromAssetId)(sourceAsset).assetReference}` +
    `&outputMint=${(0, caip_1.fromAssetId)(destinationAsset).assetReference}` +
    `&amount=${amount}` +
    (slippageBps ? `&slippageBps=${slippageBps}` : `&autoSlippage=true`) +
    `&maxAccounts=${JUPITER_TRANSACTION_MAX_ACCOUNTS}` +
    `&platformFeeBps=${commissionBps}`);
exports.getJupiterPrice = getJupiterPrice;
// @TODO: Add DAO's fee account
const getJupiterSwapInstructions = ({ apiUrl, fromAddress, toAddress, rawQuote, useSharedAccounts, }) => jupiterService_1.jupiterService.post(`${apiUrl}/swap-instructions`, {
    userPublicKey: fromAddress,
    destinationTokenAccount: toAddress,
    useSharedAccounts,
    quoteResponse: rawQuote,
    dynamicComputeUnitLimit: true,
    prioritizationFeeLamports: 'auto',
});
exports.getJupiterSwapInstructions = getJupiterSwapInstructions;
