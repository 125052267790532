"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JUPITER_COMPUTE_UNIT_MARGIN_MULTIPLIER = exports.SOLANA_RANDOM_ADDRESS = exports.JUPITER_SUPPORTED_CHAIN_IDS = exports.jupiterSupportedChainIds = void 0;
const types_1 = require("@shapeshiftoss/types");
exports.jupiterSupportedChainIds = [types_1.KnownChainIds.SolanaMainnet];
exports.JUPITER_SUPPORTED_CHAIN_IDS = {
    sell: exports.jupiterSupportedChainIds,
    buy: exports.jupiterSupportedChainIds,
};
exports.SOLANA_RANDOM_ADDRESS = '2zHKF6tqam3tnNFPK2E9nBDkV7GMXnvdJautmzqQdn8A';
// Jupiter use 40% as a compute unit margin while calculating them, some TX reverts without this
exports.JUPITER_COMPUTE_UNIT_MARGIN_MULTIPLIER = 1.4;
